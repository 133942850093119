<template>
  <!-- 菜单管理页面 -->
	<div class="box">
		<div v-show="$route.meta.isShow">
			<!-- 顶部 -->
			<div class="header">
				<div class="title">提现申请</div>
			</div>
			<div class="tableStyle">
				<a-table :columns="columns" :data-source="tabledData" :row-key="record => record.id" :pagination="false" :loading="tableLoading" bordered :scroll="{ x: 1000 }">
					<span slot="status" slot-scope="status, record">
						<a-badge v-if="record.status == 0" color="#F56C6C"/>
						<a-badge v-else-if="record.status == 1" color="#108ee9"/>
                        <a-badge v-else-if="record.status == 2" color="#67C23A"/>
						<span>{{ record.status == 0 ? '已拒绝' : record.status == 1 ? '审核中' :  record.status == 2 ? '已打款' :'' }}</span>
					</span>
                    <span slot="operation" slot-scope="operation, record">
						<span v-if="record.status == 1 && isPayment">
							<a-popconfirm title="确定要标记为已打款?" ok-text="确定" cancel-text="取消" @confirm="agree(record)">
								<a class="margin_right10">已打款</a>
							</a-popconfirm>
						</span>
                        <span  v-if="record.status == 1 && isRefuse">
							<a-popconfirm title="确定要标记为拒绝?" ok-text="确定" cancel-text="取消" @confirm="refuse(record)">
								<a class="margin_right10">拒绝</a>
							</a-popconfirm>
						</span>
						<a class="margin_right10" v-if="isDetailed" @click="detailedClick(record)">明细</a>
					</span>
				</a-table>
				<div class="page">
                    <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFns" v-show="tabledData.length > 0"/>
                </div>
			</div>
		</div>
		<router-view />
	</div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import { ApplyDrawcaShlist, AreeApply, RefuseApply } from "@/request/api/orderManage";
import { codeFn, format } from "@/utils/tools";
export default {
    components:{MyPagination},
	created() {
        this.shlistListFn();
        if (codeFn("/agent/admin/proceeds/agreeapply")) this.isPayment = true; //已打款
		if (codeFn("/agent/admin/proceeds/refuseapply")) this.isRefuse = true; //拒绝
        if (codeFn("/agent/admin/order/orderlist")) this.isDetailed = true; //明细
	},
	data() {
		return {
            pageNo: 1,
            count: 0,

            isPayment: false,
            isRefuse: false,
            isDetailed:false,
            
			formState: {
				value1: "",
				value2: "",
				value3: undefined,
				value4: undefined,
			},
			tableLoading: false, // 表格的loading图
			isDisableSearch: false, // 搜索按钮是否禁用
            columns: [
				{
					title: "代理姓名",
					dataIndex: "agentName",
					width: "120px",
                    key: "agentName",
				},
				{
					title: "手机号",
					dataIndex: "phone",
					width: "136px",
					key: "phone",
				},
				{
					title: "提现金额",
					dataIndex: "amount",
                    width: "120px",
                    align:'right',
					key: "amount",
				},
				{
					title: "申请时间",
					dataIndex: "applyTime",
					width: "184px",
					key: "applyTime",
				},
				{
					title: "审核人",
					dataIndex: "auditAdmin",
					width: "120px",
					key: "auditAdmin",
				},
				{
					title: "审核时间",
					dataIndex: "auditTime",
					width: "184px",
					key: "auditTime",
                },
                {
					title: "审核状态",
					dataIndex: "status",
					width: "120px",
                    key: "status",
                    scopedSlots: { customRender: "status" },
                },
                {
					title: "操作",
					dataIndex: "operation",
					width: "120px",
					key: "operation",
					scopedSlots: { customRender: "operation" },
				},
			],
			tabledData: [],
		};
	},
    methods: {
        showSizeChangeFns (current, pageSize) {
            this.tableLoading = true;
			this.pageNo = current;
			this.pageSize = pageSize;
			this.shlistListFn();
        },
		// 搜索按钮 , 回车搜索
        searchClick () {
            this.tableLoading = true;
            this.tabledData = []
			this.isDisableSearch = true;
			setTimeout(() => {
			    this.isDisableSearch = false;
			}, 3000);
			this.shlistListFn();
		},
		detailedClick(data) {
			this.$router.push(`/orderManage/withdrawal/detailed/${data.agentNo}`);
		},
		// 请求菜单列表数据
		shlistListFn() {
			ApplyDrawcaShlist().then(({ code, data }) => {
                if (code === 200) {
                    this.tableLoading = false;
                    this.tabledData = data.list;
                    this.count = data.count
                }
			});
		},
		// 同意
        agree (data) {
            AreeApply({
                id: data.id
            }).then(({ code, data }) => {
                if (code === 200) {
                    this.shlistListFn()
                }
			});
		},
		// 禁用
		refuse(data) {
			RefuseApply({
                id: data.id
            }).then(({ code, data }) => {
                if (code === 200) {
                    this.shlistListFn()
                }
			});
		}
	},
};
</script>
 
<style lang="less" scoped>
.box {
  min-height: 100%;
  background-color: #fff;
  padding-bottom: 56px;
  .header{
    width: 100%;
    background-color: #fff;
    .title{
      padding: 14px 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);;
    }
  }
  .tableStyle{
	background-color: #fff;
	padding: 16px;
	.page{
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;
        padding-right: 10px;
	}
  }
}
</style>